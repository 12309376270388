<template>
  <static-fullscreen-card>
    <template v-slot:header></template>
    <template v-slot:actions>

      <a-btn-refresh @click="$refs.table.updateData()" />
      <a-btn-add
        :icon="'far fa-plus-square'"
        :title="'Создать счет'"
        v-if="getAccess('financeCreate')"
        @click="createNew()"
      />
    </template>
    <portal to="v-main">
      <edit-dialog v-model="showEditDialog" :id="idEdit"></edit-dialog>
    </portal>
    <a-table-f-api
      ref="table"
      :api="url"
      :model="model"
      :useQuery="false"
      :defaults="defaults"
      @click="onClickRow($event)"
      @update-data="getBalance($event.filters)"
    >
      <template v-slot:footer>
        <div class="f-table-footer">
          <div class="f-table-row">
            <div style="margin: 6px 6px" v-if="$refs.table">
              Показано: {{ $refs.table.pager.count }}, Приход:
              <a-view-number :value="balance.debit" />, Расход:
              <a-view-number :value="balance.credit" />, Баланс:
              <a-view-number :value="balance.debit - balance.credit" />
            </div>
          </div>
        </div>
      </template>
    </a-table-f-api>
  </static-fullscreen-card>
</template>

<script>
import { getAccess } from "@/components/mixings";

export default {
  mixins: [getAccess],
  components: {
    editDialog: () => import("./dialogs/financeDialog2"),
  },
  data() {
    return {
      idEdit: 0,
      showEditDialog: false,
      title: "",
      m: this.$store.getters["config/get"].models.finance,

      url: "/mechti/finance",
      defaults: {
        sort: { key: "id", order: "DESC" },
        paramName: "finance",
      },
      balance: {
        debit: 0,
        credit: 0,
      },
    };
  },
  created() {
    this.$root.title = "Финансы";
  },
  computed: {
    permit() {
      return this.getAccess("menu.finance");
    },
    model() {
      let model = JSON.parse(JSON.stringify(this.m.list));
      model.forEach((el) => {
        if (el?.sourceList) {
          el.options = this.m[el.sourceList];
        }
      });
      return model;
    },
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.$refs.table.updateData();
      }
    },
  },
  methods: {
    onClickRow(d) {
      this.showEditDialogFun(d.row.id);
    },
    createNew() {
      this.showEditDialogFun(0);
    },
    showEditDialogFun(id) {
      this.idEdit = id;
      this.showEditDialog = true;
    },
    async getBalance(filter = {}) {
      const response = await this.$axios.get("/mechti/finance/balance", {
        params: { filters: filter },
      });
      this.balance = response.data.data;
    },
  },
};
</script>
